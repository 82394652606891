import { about, about2, about3, blog1, blog2, blog3, logo, project1, project2, project3, project4, project5, servive1, servive2, servive3, slider1, user1, user2, user3, user4, } from "../images"
import { commets } from "./inventory"

const videoUrl = 'https://player.vimeo.com/external/371592662.sd.mp4?s=ba0c453a1cb4787d897e61bc63ab9567e18f7da0&profile_id=164&oauth2_token_id=57447761'
export const primary = ''
export const secondary = ''
export const subMenu = [
   'Sobre', 'Equipa', 'Faq', 'Eventos', 'Política de Privacidade', 'Termos e Condições',
]
const blogData = [
   {
      id: '1',
      title: 'Digital Marketing: Staying Relevant To The Empowered Consumer',
      descrition: '',
      image: blog1,
      author: 'Reinaldo Vombo',
      category: ['Psicologia', 'Agencia', 'Parceria'],
      comment: commets
   },
   {
      id: '2',
      title: 'Grow Your Business With A Digital Marketing Strategy',
      descrition: '',
      image: blog2,
      author: 'Reinaldo Vombo',
      category: ['Psicologia', 'Agencia', 'Marketing digital'],
      comment: commets
   },
   {
      id: '3',
      title: '5 Inexpensive Online Tools To Get Your Business Off The Ground',
      descrition: '',
      image: blog3,
      author: 'Reinaldo Vombo',
      category: ['SEO orgânico de longo prazo', 'Agencia', 'Parceria', 'Contacto'],
      comment: commets
   },
]
export const APIDATA: [] = []

export const LOCAL_DATA = {
   logotype: logo,
   siteMenu: ['Home', 'Sobre', 'Portfolio', 'Eventos', 'Membros', 'Servicos', 'Contacto'],
   navMenus: [
      {
         id: 1,
         title: 'Home',
         href: '/'
      },
      {
         id: 2,
         title: 'Portfolio',
         href: '/portfolio'
      },
      {
         id: 3,
         title: 'Serviços',
         href: '/serviços'
      },
      {
         id: 4,
         title: 'Paginas',
         href: '#',
         subMenu: []
      },
      {
         id: 5,
         title: 'Contacto',
         href: '/contacto'
      }
   ],
   headerBanner: [slider1],
   headerSection: {
      title: 'Somos especialistas em marketing digital',
      subTitle: 'CANDIDATURAS ABERTAS!',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta doloribus, ab distinctio, a ullam non culpa quos, voluptatum commodi perspiciatis amet magni architecto ad? Ullam iusto tempore odit ratione praesentium?',
   },
   about: [
      {
         thumbnail: [about, about2],
         title: 'Sobre Nós',
         subTitle: 'EMPREENDEDORISMO COM PROPÓSITO',
         description: 'O FILuanda forma parte da Rede Global do Founder Institute; uma incubadora de empresas americana, sediada em Silicom Valley, de formação empresarial com um programa de lançamento de startups fundado na Califórnia em 2009. A Rede do Founder Institute está presente em mais de 180 cidades em todo o mundo. Em África, em 10 países; África do Sul, Burquina Faso, Congo Demócratico, Egipto, Ghana, Kenya, Namibia, Nigeria e Tunisia. Nos países de expressão portuguesa existem Chapters em Lisboa e Brazil. O Founder Institute tem ajudado a lançar mais de 4.500 empresas em seis continentes.',
         list1: ['Traffic Analysis', 'Robust Web Plan', 'raffic Analysis'],
         list2: ['We do Give Our Best', 'Highly Professionalism', 'Cross-border Operability']
      },
      {
         thumbnail: [about3],
         title: 'Sobre Nós',
         subTitle: 'EMPREENDEDORISMO COM PROPÓSITO',
         description: 'O FILuanda forma parte da Rede Global do Founder Institute; uma incubadora de empresas americana, sediada em Silicom Valley, de formação empresarial com um programa de lançamento de startups fundado na Califórnia em 2009. A Rede do Founder Institute está presente em mais de 180 cidades em todo o mundo. Em África, em 10 países; África do Sul, Burquina Faso, Congo Demócratico, Egipto, Ghana, Kenya, Namibia, Nigeria e Tunisia. Nos países de expressão portuguesa existem Chapters em Lisboa e Brazil. O Founder Institute tem ajudado a lançar mais de 4.500 empresas em seis continentes.',
         list1: ['Traffic Analysis', 'Robust Web Plan', 'raffic Analysis'],
         list2: ['We do Give Our Best', 'Highly Professionalism', 'Cross-border Operability']
      },

   ],
   services: [
      {
         _id: '1',
         thumbnail: [],
         title: 'Nosso serviços',
         subTitle: 'SERVIÇOS PARA O SEU NÉGOCIO',
         description: 'Nossos Serviços resolvem Qualquer problema',
      },
      {
         _id: '2',
         thumbnail: [servive1],
         title: 'Modern Website Design',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Nossos Serviços resolvem Qualquer problema',
      },
      {
         _id: '3',
         thumbnail: [servive2],
         title: 'SEO And Content Writing',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Nossos Serviços resolvem Qualquer problema',
      },
      {
         _id: '4',
         thumbnail: [servive3],
         title: 'App Development',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Nossos Serviços resolvem Qualquer problema',
      },
      {
         _id: '5',
         thumbnail: [videoUrl],
         title: 'We Are Digital Marketing Expert',
         subTitle: 'CANDIDATURAS ABERTAS!',
         description: 'O FILuanda forma parte da Rede Global do Founder Institute; uma incubadora de empresas americana, sediada em Silicom Valley, de formação empresarial com um programa de lançamento de startups fundado na Califórnia em 2009. A Rede do Founder Institute está presente em mais de 180 cidades em todo o mundo. Em África, em 10 países; África do Sul, Burquina Faso, Congo Demócratico, Egipto, Ghana, Kenya, Namibia, Nigeria e Tunisia. Nos países de expressão portuguesa existem Chapters em Lisboa e Brazil. O Founder Institute tem ajudado a lançar mais de 4.500 empresas em seis continentes.',
      },
   ],
   portfolio: [
      {
         id: '1',
         thumbnail: [],
         title: 'Nosso projectos',
         subTitle: 'DESCUBRA O MUNDO DE NÉGOCIO',
         description: '',
         url: '',
      },
      {
         id: '2',
         thumbnail: [project1],
         title: 'Modern Website Design',
         subTitle: '',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         url: '',
      },
      {
         id: '3',
         thumbnail: [project2],
         title: 'SEO And Content Writing',
         subTitle: '',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         url: '',
      },
      {
         id: '4',
         thumbnail: [project3],
         title: 'App Development',
         subTitle: '',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         url: '',
      },
      {
         id: '5',
         thumbnail: [project4],
         title: 'App Development',
         subTitle: '',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         url: '',
      },
      {
         id: '6',
         thumbnail: [project5],
         title: 'App Development',
         subTitle: '',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         url: '',
      },
   ],

   testimonial: [
      {
         photo: user1,
         name: 'Jonh doe',
         position: 'CEO',
         message: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis',
      },
      {
         photo: user1,
         name: 'Jonh doe',
         position: 'CEO',
         message: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis',
      },
      {
         photo: user1,
         name: 'Jonh doe',
         position: 'CEO',
         message: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis',
      },
   ],
   team: [
      {
         _id: '1',
         thumbnail: user1,
         name: 'Jonh doe',
         jobPosition: 'CEO',
      },
      {
         _id: '2',
         thumbnail: user2,
         name: 'Jonh doe',
         jobPosition: 'Gerente de Operações',
      },
      {
         _id: '3',
         thumbnail: user3,
         name: 'Jonh doe',
         jobPosition: 'Chefe de Finanças',
      },
      {
         _id: '3',
         thumbnail: user4,
         name: 'Jonh doe',
         jobPosition: 'Chefe de Finanças',
      },
   ],
   colores: {
      primary: "",
      secondary: "",
      sucesse: "",
      danger: ""
   },
   blogPost: {
      quote: 'post recentes',
      title: 'Nóticias & Artigos recente',
      post: blogData
   },
   eventes: [
      {
         _id: '1',
         thumbnail: blog1,
         title: 'Digital Marketing: Staying Relevant To The Empowered Consumer',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         category: ['Psicologia', 'Agencia', 'Parceria']
      },
      {
         _id: '2',
         thumbnail: blog2,
         title: 'Grow Your Business With A Digital Marketing Strategy',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         category: ['Psicologia', 'Agencia', 'Marketing digital'],

      },
      {
         _id: '3',
         thumbnail: blog3,
         title: '5 Inexpensive Online Tools To Get Your Business Off The Ground',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         category: ['SEO orgânico de longo prazo', 'Agencia', 'Parceria', 'Contacto']
      },
   ],
   contact: {
      phone: ['(+244)912 345 6789', '(+244)912 345 6789'],
      whatsapp: 'https://web.whatsapp.com',
      facebook: 'https://www.facebook.com',
      twitter: 'https://x.com/home',
      linkedIn: 'https://www.linkedin.com',
      instagram: '',
      email: ['info@exemol'],
      youtube: 'https://www.youtube.com/',
      address: '5919 Trussville St, Birmingham, America',
   },
   footer: {
      logo: logo,
      descrition: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus, perferendis. Amet sit sed, minima odio quis similique, tenetur molestias eum reiciendis vitae doloremque adipisci placeat, id aperiam unde sequi ea.',
      event: 'EVENTOS RECENTES',
      events: blogData
   }


}


