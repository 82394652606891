import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import MobileNav from './ui/mobileNav'
import { Toaster } from 'sonner'

interface LayoutProps {
   children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {

   return (
      <>
         <Navbar />
         <main>
            {children}
         </main>
         <Footer />
         <MobileNav />
         <Toaster position="bottom-right" richColors />
      </>
   )
}

export default Layout
