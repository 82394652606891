import { useQuery } from "@tanstack/react-query";
import { getApiContent } from "../utils/db/query";

export const useFetch = (url: string) => {
   return useQuery({
      queryKey: ["content"],
      queryFn: () => getApiContent(url),
      staleTime: 60000, // Data is fresh for 1 minute
      refetchOnWindowFocus: true, // Refetch on window focus
      refetchOnReconnect: true, // Refetch on reconnect
   });
};