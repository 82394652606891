import React, { useState } from 'react'
import { Link } from 'gatsby'
import { LOCAL_DATA, primary } from '../config/site-content'
import { motion, useScroll, useMotionValueEvent } from 'framer-motion'
import { IServerData } from '../interface/data'
import { skylabApi } from '../config/skylab'
interface INavProps {
   serverData?: IServerData
}

const Navbar = ({ serverData }: INavProps) => {
   const menu = (serverData && serverData.siteMenu.length > 0 ? serverData.siteMenu : LOCAL_DATA.siteMenu) as string[];
   const logo = LOCAL_DATA.logotype

   const { scrollY } = useScroll()

   const [hidden, setHidden] = useState(false)
   useMotionValueEvent(scrollY, 'change', (latest) => {
      const previous = scrollY.getPrevious()
      if (latest > previous && latest > 150) {
         setHidden(true)
      } else {
         setHidden(false)
      }
   })

   return (
      <motion.header
         variants={{
            visible: { y: 0 },
            hidden: { y: '-200%' }
         }}
         animate={hidden ? 'hidden' : 'visible'}
         className={`fixed w-full z-10 h-[62px] pb-3 rounded-md ${hidden ? 'bg-transparent' : 'bg-black md:bg-[#00000085]'}`}>
         <nav className='px-4 py-2 flex items-center justify-between lg:px-24 navbar'>
            <div className='w-16'>
               <Link to='/'>
                  <img src={logo} width={400} height={400} alt="logo" />
               </Link>
            </div>
            <div className='nav-menu'>
               <ul className='list-none flex items-center justify-center gap-8'>
                  {menu.map((item, index) => (
                     <li key={index + 1} className='relative group'>
                        <Link to={index === 0 ? '/' : '/' + item.toLowerCase()} className='text-white'>{item}</Link>
                     </li>
                  ))}
               </ul>
            </div>
            <Link to='/contacto' className='bg-primmary p-2 rounded-md text-white hover:bg-orange-600 transition-colors hidden lg:block' style={{ background: primary ? primary : '' }}>Solicitar serviço</Link>
         </nav>
      </motion.header>
   )
}

export default Navbar
export async function getServerData() {
   try {
      const res = await fetch(skylabApi)


      if (!res.ok) {
         throw new Error(`Response failed with status ${res.status}`);
      }

      return {
         props: await res.json(),
      }
   } catch (error) {
      return {
         notFound: true,
      };
   }
}