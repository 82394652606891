import React, { useState } from 'react'
import { Link } from 'gatsby'
import { footerBg } from '../images'
import { Facebook, Instagram, Twitter, Youtube } from 'lucide-react'
import { LOCAL_DATA } from '../config/site-content'
import Button from './ui/button'
import { IContact, IEvents } from './types'
import { useFetch } from '../hooks/use-fetch'
import { contentUrl } from '../utils/db'
import { toast } from 'sonner'

const date = new Date()
const year = date.getFullYear()
const Footer = () => {
   const [email, setEmail] = useState('')
   const { data } = useFetch(contentUrl)
   const contact = (data?.contact.length > 0 ? data.contact : LOCAL_DATA.footer) as IContact;
   const logotype = (data?.logotype ? data?.logotype : LOCAL_DATA.logotype) as string;
   const events = (data?.events.length > 0 ? data.events : LOCAL_DATA.footer.events) as IEvents[];
   const mediaIcon = [
      { url: contact.facebook, icon: <Facebook /> },
      { url: contact.twitter, icon: <Twitter /> },
      { url: contact.instagram, icon: <Instagram /> },
      { url: contact.youtube, icon: <Youtube /> },
   ]
   function subscribeToNewsLatter(e: React.FormEvent<HTMLFormElement>) {

      e.preventDefault()
      if (!email) {
         toast.warning('Por favor insira seu email')
         return
      }

      toast.success('Subscrição feita com sucesso')
      setEmail('')
   }
   return (
      <footer className='relative bg-cover bg-center p-[20px] lg:p-[35px] footer' style={{ backgroundImage: `url(${footerBg})` }}>
         <div className="flex flex-col items-center sm:flex-row">
            <div className='relative w-full sm:w-[25%] flex-1 text-white p-[30px] text-center md:text-left space-y-4'>
               <Link to='/'>
                  <img src={logotype} className='w-24 h-24' width={200} height={200} alt="logo" />
               </Link>
               <p>
                  {process.env.GATSBY_SITE_DESCRIPTION
                     ? process.env.GATSBY_SITE_DESCRIPTION
                     : 'We are redefining the way businesses grow and connect with their audiences. Our cutting-edge platform integrates advanced analytics'}</p>
            </div>
            <div className='relative w-full lg:w-[25%] flex-1 text-white p-[30px] space-y-4'>
               <h2 className='text-base text-white'>EVENTOS RECENTES</h2>
               {events.map((item, index) => (
                  <div className='mb-4' key={index}>
                     <span className='text-gray-400'>19-August-2021</span>
                     <Link to={`/eventos/${item._id}`}>
                        <h3 className='text-sm transition-colors hover:text-primmary'>{item.title}</h3>
                     </Link>
                  </div>
               ))}
            </div>
            <div className='relative w-full lg:w-[25%] flex-1 text-white p-[30px] space-y-4'>
               <h2 className='text-base text-white'>NEWSLETTER</h2>
               <p>Subscreva-se a nossa newsletter e fique por dentro de todas novidades</p>
               <form onSubmit={subscribeToNewsLatter} className='space-y-2'>
                  <input
                     onChange={(e) => setEmail(e.target.value)}
                     type="text"
                     className='w-full p-3 text-black rounded-md focus-within:outline-none'
                     placeholder='Escreva seu email' />
                  <Button type='submit' className='w-full transition-colors hover:bg-orange-600'>Subscrever</Button>
               </form>
            </div>
         </div>
         <div className="relative text-white flex items-center justify-center gap-4">
            {mediaIcon.map((icon, index) => (
               <a href={icon.url} key={index}>
                  <i className='bg-[#ffffff42] w-9 h-9 rounded-full flex items-center justify-center transition-colors hover:bg-orange-400'>
                     {icon.icon}
                  </i>
               </a>
            ))}
         </div>
         <hr className='w-full bg-[#ffffff42] m-4' />
         <div className='relative px-[20px] md:px-[50px] lg:px-[100px] text-center text-white'>
            <p>© {year} <a href="https://ahoura.ao/">Ahoura.</a></p>
         </div>
      </footer>
   )
}

export default Footer;