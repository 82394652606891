import React from 'react';
import './src/styles/global.css';
import './src/styles/swiper.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import RootElement from './src/components/root-elemente';
import RootLayout from './src/components/layout';

export const wrapPageElement = ({ element }) => {
  return (
    <RootElement>
      <RootLayout>{element}</RootLayout>;
    </RootElement>
  );
};
